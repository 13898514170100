import { Card, Text } from '@mindbaz/ui-v2';
import { styled } from '@mui/material';

export const Flex = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& p': {
    marginLeft: 5,
  },
});

export const CardCustom = styled(Card)({
  height: '100%',
  '& > div': {
    height: '100%',
  },
});

export const AutocompleteCustom = styled('div')({
  marginBottom: 15,
});

export const Container = styled('div')({
  height: '100%',
  backgroundColor: 'white',
  borderRadius: 12,
  padding: '0.5rem',
  '&.scrollable': {
    maxHeight: '250px',
    overflow: 'auto',
  },
});

export const StyledLabel = styled(Text)({
  marginLeft: '0.25rem',
});
