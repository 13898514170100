import React, { useCallback } from 'react';
import { useTranslation } from '@mindbaz/app-manager';
import { Autocomplete, Grid, Tabs } from '@mindbaz/ui-v2';

import {
  Flex,
  CardCustom,
  AutocompleteCustom,
  Container,
  StyledLabel,
} from './styledComponents';
import {
  ITabInformations,
  TBaseCard,
  TCard,
  TOptionsAutocomplete,
} from '../types';

const THIRD_ELEMENT = 3;
const TAKE_ALL_WIDTH = 12;
const TAKE_TWO_THIRDS_WIDTH = 8;
const TAKE_ONE_THIRDS_WIDTH = 4;

const TabInformations = ({
  cards,
  typeTab,
  options,
  value,
  onChange,
}: ITabInformations) => {
  const { translate } = useTranslation();

  const gridSize = useCallback(
    (index: number) => {
      if (index === THIRD_ELEMENT && typeTab === 'performances')
        return TAKE_TWO_THIRDS_WIDTH;
      if (index === THIRD_ELEMENT) return TAKE_ALL_WIDTH;
      return TAKE_ONE_THIRDS_WIDTH;
    },
    [typeTab]
  );

  return (
    <>
      {typeTab === 'performances' && (
        <AutocompleteCustom>
          <Autocomplete
            label={translate('dailyReport.databases')}
            getOptionLabel={(option: TOptionsAutocomplete) => option.label}
            onChange={onChange}
            options={options}
            value={value}
            width={300}
            disableClearable
          />
        </AutocompleteCustom>
      )}
      <Grid container spacing={3}>
        {cards.map((rows: TBaseCard[], index) =>
          rows.length > 1 ? (
            <Grid
              key={rows[0].label}
              item
              xl={gridSize(index)}
              lg={gridSize(index)}
              md={gridSize(index)}
            >
              <Container>
                <Tabs items={rows} />
              </Container>
            </Grid>
          ) : (
            rows.map(({ label, icon, isScrollable, content }: TCard) => (
              <Grid
                key={label}
                item
                xl={gridSize(index)}
                lg={gridSize(index)}
                md={gridSize(index)}
              >
                <CardCustom
                  title={
                    <Flex>
                      {icon}
                      <StyledLabel variant="h4">{label}</StyledLabel>
                    </Flex>
                  }
                >
                  <Container className={isScrollable ? 'scrollable' : ''}>
                    {content}
                  </Container>
                </CardCustom>
              </Grid>
            ))
          )
        )}
      </Grid>
    </>
  );
};

export default TabInformations;
