import React, { useCallback } from 'react';
import { Translation, useTranslation } from '@mindbaz/app-manager';
import { Text, Button } from '@mindbaz/ui-v2';

import {
  StyledContainer,
  StyledNotListedLocationOutlinedIcon,
} from './styledComponents';

const Gone = () => {
  const { translate } = useTranslation();

  const BoldText = {
    bold: <strong />,
  };

  const handleClick = useCallback(
    () =>
      window.location.replace(
        `${process.env.REACT_APP_MINDBAZ_URL}superset` || ''
      ),
    []
  );

  return (
    <StyledContainer>
      <StyledNotListedLocationOutlinedIcon />
      <Text variant="h1" align="center">
        {translate('gonePage.title')}
      </Text>
      <br />
      <Text align="center">
        <Translation components={BoldText}>gonePage.description1</Translation>
      </Text>
      <Text align="center">{translate('gonePage.description2')}</Text>
      <br />
      <Button onClick={handleClick}>{translate('gonePage.button')}</Button>
    </StyledContainer>
  );
};

export default Gone;
