import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AccessDenied from './Common/AccessDenied';

const pages = {
  AccessDenied: '/accessdenied',
};

export const publicPages = Object.values(pages);

const PublicRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/accessdenied" element={<AccessDenied />} />
    </Routes>
  </BrowserRouter>
);

export default PublicRoutes;
