import React, { useMemo } from 'react';
import { Translation, useTranslation } from '@mindbaz/app-manager';
import { Text } from '@mindbaz/ui-v2';

import { StyledTitle, Container, SpanColor } from './styledComponents';

const TitleDescriptionBlock = ({
  title,
  description,
  moreDescription,
  values,
}: {
  title: string;
  description: string;
  moreDescription?: any;
  values?: string;
}) => {
  const { translate } = useTranslation();

  const components = useMemo(
    () => ({
      bold: <SpanColor />,
      moreDescription,
    }),
    [moreDescription]
  );

  return (
    <Container>
      <StyledTitle variant="h1">{translate(title)}</StyledTitle>
      <Text>
        <Translation values={{ values }} components={components}>
          {description}
        </Translation>
      </Text>
    </Container>
  );
};

export default TitleDescriptionBlock;
