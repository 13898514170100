import React, { useCallback, useMemo } from 'react';
import { useTranslation, getCookie } from '@mindbaz/app-manager';
import { Button, Text } from '@mindbaz/ui-v2';
import { useQuery } from '@tanstack/react-query';

import { getDashboardsAccess } from 'packages/endpoints/access';
import { getPerson } from 'packages/endpoints/common';
import getCustomDashboards from 'packages/endpoints/dashboards';
import TitleDescriptionBlock from 'components/common/TitleDescriptionBlock';
import Card from '../../components/common/Cards/Card';
import StyledNav from './styledComponents';
import { TCards, TCustomDashboards } from './types';

const Dashboards = () => {
  const { translate } = useTranslation();

  const { data: dashboardsAccess } = useQuery(['dashboards-access'], () =>
    getDashboardsAccess()
  );

  if (!dashboardsAccess.userHasAccess) {
    window.location.replace(`${process.env.REACT_APP_RUBIXO_URL}accessdenied`);
  }

  const { data: person } = useQuery(['get-person'], () => getPerson());
  const { data: customDashboards } = useQuery(
    ['getDatabases'],
    () => person && getCustomDashboards(person.idCompany)
  );

  const CARD_TYPE: TCards[] = useMemo(
    () => [
      {
        typeName: 'deliverabilityReport',
        link: process.env.REACT_APP_DELIVERABILITY_IMPORT_LINK || '',
        content: translate('dashboards.menuCards.deliverability.content'),
        titleIcon: 'mark_email_read',
        titleText: translate('dashboards.menuCards.deliverability.title'),
      },
      {
        typeName: 'campaignStatistics',
        link: process.env.REACT_APP_CAMPAIGN_STATISTIC_LINK || '',
        content: translate('dashboards.menuCards.campaign.content'),
        titleIcon: 'insert_chart',
        titleText: translate('dashboards.menuCards.campaign.title'),
      },
    ],
    [translate]
  );

  const handleCardClick = useCallback((link: string) => {
    const token = getCookie('at');
    window.location.replace(`${link}${token}`);
  }, []);

  return (
    <>
      <TitleDescriptionBlock
        title="dashboards.welcome"
        description="dashboards.presentation"
      />
      <StyledNav>
        {CARD_TYPE.map(
          ({ typeName, content, titleIcon, titleText, link }: TCards) => (
            <div key={typeName} onClick={() => handleCardClick(link)}>
              <Card
                content={content}
                titleIcon={titleIcon}
                titleText={titleText}
              />
            </div>
          )
        )}
      </StyledNav>
      <Text variant="h1">{translate('dashboards.customDashboards')}</Text>
      {customDashboards && customDashboards.length > 0 ? (
        <StyledNav>
          {customDashboards.map(
            ({ idCustomDashboard, name, dashboardUrl }: TCustomDashboards) => (
              <div
                key={idCustomDashboard}
                onClick={() =>
                  handleCardClick(
                    `${process.env.REACT_APP_CUSTOM_LINK}${dashboardUrl}&token=`
                  )
                }
              >
                <Card
                  titleIcon="dashboard_customize"
                  titleText={name.charAt(0).toUpperCase() + name.slice(1)}
                />
              </div>
            )
          )}
        </StyledNav>
      ) : (
        <StyledNav
          onClick={() => {
            window.location.href =
              'mailto:serviceclient@mindbaz?subject=custom%20dashboards';
          }}
        >
          <Card
            titleIcon="dashboard_customize"
            titleText={translate('dashboards.needCustomDashboard')}
            button={<Button>Contact Us</Button>}
          />
        </StyledNav>
      )}
    </>
  );
};
export default Dashboards;
