import React from 'react';
import { Text } from '@mindbaz/ui-v2';

import dashboardsLogo from 'assets/Logo_Rubixo.svg';

import {
  ContainerHeader,
  ItemNavigation,
  ListNavigation,
  Navigation,
  StyledImg,
} from '../../styledComponents';

type THeader = {
  navigations: string[];
};

const Header = (props: THeader) => {
  const { navigations } = props;
  return (
    <ContainerHeader>
      <StyledImg src={dashboardsLogo} alt="Mindbaz logo" loading="lazy" />
      <ListNavigation>
        {navigations.map((navigation) => (
          <Navigation>
            <ItemNavigation href={`${navigation}`}>
              <Text>
                {navigation.charAt(0).toUpperCase()}
                {navigation.slice(1)}
              </Text>
            </ItemNavigation>
          </Navigation>
        ))}
      </ListNavigation>
    </ContainerHeader>
  );
};

export default Header;
