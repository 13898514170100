import { fetch } from '@mindbaz/app-manager';

// eslint-disable-next-line import/prefer-default-export
export const isAdminMotherCompagnies = async () => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}features/canAdmin`,
  }).then((result: any) => {
    return result.data.data;
  });
};
