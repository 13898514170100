import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://e5cfa4bf421c42ba891e297db0c1dde9@gltp-sup.mindbaz.info/7',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_API_ENV,
  debug: process.env.REACT_APP_API_ENV !== 'production',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Request failed with status code 403',
  ],
  // ignore error from localhost.
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
