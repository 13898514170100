import { styled } from '@mui/material';
import { colors, Container } from '@mindbaz/ui-v2';
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';

export const StyledContainer = styled(Container)({
  textAlign: 'center',
});

export const StyledNotListedLocationOutlinedIcon = styled(
  NotListedLocationOutlinedIcon
)({
  width: '10rem',
  height: '10rem',
  color: colors.MediumGrey,
  marginTop: '5rem',
  marginBottom: '2rem',
});
