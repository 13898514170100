import React from 'react';
import { BarChart } from '@mindbaz/ui-v2';

import ContentWrapper from './styledComponents';
import { IChart } from './types';

const useChart = (props: IChart) => {
  const {
    data,
    colors,
    layout,
    isGrouped,
    innerPadding,
    margin,
    width,
    padding,
    axisBottom,
  } = props;

  return (
    <ContentWrapper>
      <BarChart
        axisLeft={{
          tickPadding: 16,
          tickSize: 0,
        }}
        colors={colors}
        data={data}
        groupMode={isGrouped ? 'grouped' : 'stacked'}
        groupedWithTooltip
        height={225}
        layout={layout}
        margin={margin}
        padding={padding || 0.2}
        solo={false}
        isInteractive
        width={width || 450}
        innerPadding={innerPadding}
        axisBottom={axisBottom || null}
      />
    </ContentWrapper>
  );
};

export default useChart;
