import React, { Suspense } from 'react';
import { Loader } from '@mindbaz/ui-v2';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Gone from 'pages/Common/Gone';
import NoFound from 'pages/Common/NoFound';
import Dashboards from './Dashboards';
import Layout from '../modules/Layout';
import DailyReport from './DailyReport';
import SafeSend from './SafeSend';
import Features from './Features';

const PrivateRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/dashboards" element={<Dashboards />} />
            <Route path="/" element={<Features />} />
            <Route path="/snapshot" element={<DailyReport />} />
            <Route path="/safesend" element={<SafeSend />} />
            <Route path="/gone" element={<Gone />} />
            <Route path="*" element={<NoFound />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </Suspense>
  );
};

export default PrivateRoutes;
