import { fetch } from '@mindbaz/app-manager';

const getCustomDashboards = async (idCompany: number) => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}customdashboards/${idCompany}`,
  }).then((result: any) => {
    return result.data.data;
  });
};

export default getCustomDashboards;
