import { useMemo } from 'react';
import { useTranslation } from '@mindbaz/app-manager';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

const useDataGrid = () => {
  const { translate } = useTranslation();

  const columns = useMemo(
    () => [
      {
        field: 'siteName',
        flex: 0.8,
        headerName: translate('safeSend.tabs.header.nameBDD'),
      },
      {
        field: 'nbBlackListedEmails',
        flex: 0.8,
        headerName: translate('safeSend.tabs.header.emails'),
        renderCell: (params: GridRenderCellParams<number>) =>
          params.value?.toLocaleString('FR-fr'),
      },
      {
        field: 'nbSuspiciousActions',
        flex: 0.8,
        headerName: translate('safeSend.tabs.header.actions'),
        renderCell: (params: GridRenderCellParams<number>) =>
          params.value?.toLocaleString('FR-fr'),
      },
      {
        field: 'currentSubState',
        flex: 0.8,
        headerName: translate('safeSend.tabs.header.status'),
      },
    ],
    [translate]
  );

  return {
    columns,
  };
};

export default useDataGrid;
