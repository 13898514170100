import React, { useMemo } from 'react';
import { Translation, useTranslation } from '@mindbaz/app-manager';
import { Text } from '@mindbaz/ui-v2';

import {
  Banner,
  SpanColor,
  SpanDiscover,
} from 'pages/Features/styledComponents';

const NewFeatureBanner = ({ newFeature }: { newFeature: string }) => {
  const { translate } = useTranslation();

  const components = useMemo(
    () => ({
      bold: <SpanColor />,
      discover: <SpanDiscover />,
    }),
    []
  );

  // const handleDiscover = useCallback(() => {
  //   window.location.replace(
  //     `${process.env.REACT_APP_MINDBAZ_URL}${newFeature}`
  //   );
  // }, [newFeature]);

  return (
    <div>
      {newFeature && (
        <Banner>
          <Text align="center">
            <Translation components={components}>
              {translate(`features.newFeatureBanner.${newFeature}`)}
            </Translation>
            {/* <PointerCursor onClick={handleDiscover}>
              <Translation components={components}>
                {translate('features.newFeatureBanner.discover')}
              </Translation>
            </PointerCursor> */}
          </Text>
        </Banner>
      )}
    </div>
  );
};

export default NewFeatureBanner;
