import { Text } from '@mindbaz/ui-v2';
import { styled } from '@mui/material';

export const StyledTitle = styled(Text)({
  marginBottom: '1rem',
});

export const Container = styled('div')({
  width: '75%',
  marginBottom: '20px',
});

export const SpanColor = styled('span')({
  color: 'black',
  fontWeight: 'bold',
});
