import React from 'react';
import { Icon } from '@mindbaz/ui-v2';

import ICard from 'packages/types/Cards/ICard';
import {
  StyledCard,
  StyledCardTitleWrapper,
  StyledCardTitle,
  StyledTextContent,
  ButtonContainer,
} from './styledComponents';

const Card = ({ titleIcon, titleText, content, button }: ICard) => {
  return (
    <StyledCard
      isAnimated
      isClickable
      title={
        <StyledCardTitleWrapper>
          <Icon size={50} color="secondary">
            {titleIcon}
          </Icon>

          <StyledCardTitle variant="h3">{titleText}</StyledCardTitle>
        </StyledCardTitleWrapper>
      }
    >
      {content && (
        <StyledTextContent align="center">{content}</StyledTextContent>
      )}
      {button && <ButtonContainer>{button}</ButtonContainer>}
    </StyledCard>
  );
};

export default Card;
