import { colors, Text } from '@mindbaz/ui-v2';
import { Link, styled } from '@mui/material';

export const Flex = styled('div')({
  display: 'flex',
  '&.justify-between': {
    justifyContent: 'space-between',
  },
  '&.justify-end': {
    justifyContent: 'end',
    alignItems: 'center',
  },
  '&.justify-align-center': {
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
  '&.align-center': {
    alignItems: 'center',
  },
  '&.mb-5': {
    marginBottom: 5,
  },
  '&.mb-10': {
    marginBottom: 10,
  },
  '&.mb-20': {
    marginBottom: 20,
  },
  '&.mt-50': {
    marginTop: 50,
  },
  '&.flex-column': {
    flexDirection: 'column',
  },
});

export const Container = styled('div')({
  '&.mb-10': {
    marginBottom: 10,
  },
});

export const AutocompleteSize = styled('div')({
  display: 'grid',
  width: '300px',
});

export const LinkCustom = styled(Link)({
  color: colors.GreyBlack,
  textDecoration: 'underline',
});

export const TextCustom = styled(Text)({
  '&.ml-5': {
    marginLeft: 5,
  },
  '&.ml-10': {
    marginLeft: 10,
  },
  '&.bold': {
    fontWeight: 'bold',
  },
});
