import { fetch } from '@mindbaz/app-manager';

export const getDailyReportAccess = async () => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}dailyreport/access `,
  }).then((result: any = {}) => {
    return result.data.data || result;
  });
};

export const getDashboardsAccess = async () => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}superset/access `,
  }).then((result: any = {}) => {
    return result.data.data || result;
  });
};

export const getSafeSendAccess = async () => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}safesend/subscription`,
  }).then((result: any) => {
    return result.data.data;
  });
};
